
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "primeicons/primeicons.css";

:root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.nav-link-header{
  color:#03045E !important;
}
.p-message-close{
  position: absolute;
  right: 2rem;
}
.error-message {
  color: red;
}
.cursur-pointer{
  cursor: pointer;
}